<template>
  <span v-html="rendered"></span>
</template>

<script>
import MarkdownIt from 'markdown-it'
export default {
  name: 'Markdown',
  props: ['content'],
  computed: {
    rendered: function () {
      const md = new MarkdownIt()
      md.set({ linkify: true })
      return md.render(this.content)
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
